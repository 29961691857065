<template>
  <div>
    <!-- 中文 -->
    <div v-if="$store.state.show">
      <h2>TiSDK合规使用说明</h2>
      <p>
        根据《个人信息保护法》、《数据安全法》、《网络安全法》等法律法规和监管部门规章要求，App开发运营者（以下简称为“开发者”）在提供网络产品服务时应尊重和保护最终用户的个人信息，不得违法违规收集使用个人信息，保证和承诺就个人信息处理行为获得最终用户的授权同意，遵循最小必要原则，且应当采取有效的技术措施和组织措施确保个人信息安全。为帮助开发者在使用TiSDK的过程中更好地落实用户个人信息保护相关要求，避免出现侵害最终用户个人信息权益的情形，特制定本合规使用说明，供开发者在接入使用TiSDK服务时参照自查和合理配置，不断提升个人信息保护水平。      </p>
     <br>
      <h3><b>一、TiSDK配置能力说明</b></h3>
      <ul>
        <li><b>1、SDK扩展业务功能的配置说明</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明SDK各项扩展业务功能介绍及对应关闭的配置方式、示例。<br>
          <b>接入说明：</b>TiSDK提供的扩展业务功能为：（1）实时视频、图片的人像美化、面部AR特效等能力。TiSDK为开发者提供退出上述功能的接口，开发者可以调用接口，向最终用户提供对应的能力。开发者需遵守相关法律法规的要求，在App内为最终用户提供上述业务功能，并保证在最终用户点击该功能后能正常调用TiSDK的能力接口。  
        </p>

        <li><b>2、SDK可选个人信息的配置说明</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明SDK各项可选个人信息使用目的、场景及对应关闭的配置方式、示例。<br>
<b>接入说明：</b>对于TiSDK可选收集的个人信息的控制，开发者可以参考如下配置文档的内容进行配置操作。因相关信息的不收集将会对其对应的功能造成影响，请开发者结合业务实际需要进行合理配置。
TiSDK可选个人信息的说明
TiSDK主要提供音视频、图片的AR特效处理能力，所以不会采集任何用户个人信息，具体如下
        </p>
        <img src="@/assets/developer/info.jpg" alt="个人信息示意图" />

        <li><b>3、SDK申请系统权限的说明</b></li>
        <p>
          TiSDK在提供服务时会最小化的使用系统权限，TiSDK本身不会自动开启任何的敏感隐私权限，但是SDK的某些功能会对设备的权限有要求，需要开启后才能正常使用，开发者需要根据实际使用的功能，申请对应的系统权限并向用户告知征得其同意，TiSDK本身不收集用户任何信息，只提供针对传入的音视频等图形图像进行处理渲染等服务，下方是权限的用途说明
        </p>
        <img src="@/assets/developer/plist.jpg" alt="权限示意图" />

        <li><b>4、SDK初始化及业务功能调用时机</b></li>
        <p>
          为了避免开发者的应用在未获取用户的同意前，SDK提前启动收集使用用户的个人信息（使用权限）。TiSDK建议开发者的应用，应该在获取用户的同意后才能调用初始化TiSDK。
        </p>

        <li><b>5、SDK隐私政策披露要求与示例</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应提供App向最终用户披露SDK隐私政策条款的示例，包括SDK名称、公司名称、处理个人信息种类及目的、采集方式、隐私政策链接等内容。<br>
<b>接入说明：</b> 在您接入、使用TiSDK服务前，我们会告知TiSDK的名称，收集个人信息类型&权限，使用目的、隐私政策链接等信息，您需要在您接入TiSDK的产品内告知您的用户，并获取用户的同意或取得其他合法性基础。<br><br>
<b>披露示例（仅供参考，请以实际合作情况为准）：</b><br>
<b>SDK名称：</b> TiSDK<br>
<b>合作方主体：</b> 南京拓幻智能科技有限公司<br>
<b>信息及权限名称：</b>根据上述权限要求填写具体的信息类型<br>
<b>使用目的：</b> 提供视频、图片人像美化、AR特效技术<br>
<b>隐私政策链接：</b> <a href="https://tillusory.com/privacy">https://tillusory.com/privacy</a>
        </p>

        <li><b>6、最终用户同意方式的示例</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明App获取最终用户授权同意的建议方式，其中需要取得最终用户单独同意的，应显著提示并给出示例。<br>
          <b>接入说明：</b> App首次运行时应当有隐私弹窗，隐私弹窗中应公示简版隐私政策内容并附完整版隐私政策链接，并明确提示最终用户阅读并选择是否同意隐私政策；隐私弹窗应提供同意按钮和拒绝同意的按钮，并由最终用户主动选择。<br>
<b>披露示例：</b><br>
        </p>
        <img src="@/assets/developer/example.png" alt="用户同意方式" />


        <li><b>7、最终用户行使权利的配置说明</b></li>
        <p>
          <b>要求内容：</b> 最终用户对其个人信息的处理享有知情、决定、查阅、复制、补充、更正、撤回授权同意、删除、注销账号等权利。以嵌入接口形式向最终用户提供行使权利的，应提供接口调用方式、示例。<br>
<b>接入说明：</b> 开发者在其App中集成TiSDK后，TiSDK的正常运行会收集必要的最终用户上述设备对应权限进行服务。开发者应根据相关法律法规为最终用户提供行使个人信息主体权利的路径或功能，需要TiSDK配合的，请与TiSDK及时进行联系，我们将与开发者协同妥善解决最终用户的诉求。联系电话：025-66048356，客服电子邮箱地址：sales@tillusory.cn
        </p>

      </ul>
     
     
    </div>
    <!-- 英文 -->
    <div v-else>
      <h2>TiSDK合规使用说明</h2>
      <p>
        根据《个人信息保护法》、《数据安全法》、《网络安全法》等法律法规和监管部门规章要求，App开发运营者（以下简称为“开发者”）在提供网络产品服务时应尊重和保护最终用户的个人信息，不得违法违规收集使用个人信息，保证和承诺就个人信息处理行为获得最终用户的授权同意，遵循最小必要原则，且应当采取有效的技术措施和组织措施确保个人信息安全。为帮助开发者在使用TiSDK的过程中更好地落实用户个人信息保护相关要求，避免出现侵害最终用户个人信息权益的情形，特制定本合规使用说明，供开发者在接入使用TiSDK服务时参照自查和合理配置，不断提升个人信息保护水平。      </p>
     <br>
      <h3><b>一、TiSDK配置能力说明</b></h3>
      <ul>
        <li><b>1、SDK扩展业务功能的配置说明</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明SDK各项扩展业务功能介绍及对应关闭的配置方式、示例。<br>
          <b>接入说明：</b>TiSDK提供的扩展业务功能为：（1）实时视频、图片的人像美化、面部AR特效等能力。TiSDK为开发者提供退出上述功能的接口，开发者可以调用接口，向最终用户提供对应的能力。开发者需遵守相关法律法规的要求，在App内为最终用户提供上述业务功能，并保证在最终用户点击该功能后能正常调用TiSDK的能力接口。  
        </p>

        <li><b>2、SDK可选个人信息的配置说明</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明SDK各项可选个人信息使用目的、场景及对应关闭的配置方式、示例。<br>
<b>接入说明：</b>对于TiSDK可选收集的个人信息的控制，开发者可以参考如下配置文档的内容进行配置操作。因相关信息的不收集将会对其对应的功能造成影响，请开发者结合业务实际需要进行合理配置。
TiSDK可选个人信息的说明
TiSDK主要提供音视频、图片的AR特效处理能力，所以不会采集任何用户个人信息，具体如下
        </p>
        <img src="@/assets/developer/info.jpg" alt="个人信息示意图" />

        <li><b>3、SDK申请系统权限的说明</b></li>
        <p>
          TiSDK在提供服务时会最小化的使用系统权限，TiSDK本身不会自动开启任何的敏感隐私权限，但是SDK的某些功能会对设备的权限有要求，需要开启后才能正常使用，开发者需要根据实际使用的功能，申请对应的系统权限并向用户告知征得其同意，TiSDK本身不收集用户任何信息，只提供针对传入的音视频等图形图像进行处理渲染等服务，下方是权限的用途说明
        </p>
        <img src="@/assets/developer/plist.jpg" alt="权限示意图" />

        <li><b>4、SDK初始化及业务功能调用时机</b></li>
        <p>
          为了避免开发者的应用在未获取用户的同意前，SDK提前启动收集使用用户的个人信息（使用权限）。TiSDK建议开发者的应用，应该在获取用户的同意后才能调用初始化TiSDK。
        </p>

        <li><b>5、SDK隐私政策披露要求与示例</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应提供App向最终用户披露SDK隐私政策条款的示例，包括SDK名称、公司名称、处理个人信息种类及目的、采集方式、隐私政策链接等内容。<br>
<b>接入说明：</b> 在您接入、使用TiSDK服务前，我们会告知TiSDK的名称，收集个人信息类型&权限，使用目的、隐私政策链接等信息，您需要在您接入TiSDK的产品内告知您的用户，并获取用户的同意或取得其他合法性基础。<br><br>
<b>披露示例（仅供参考，请以实际合作情况为准）：</b><br>
<b>SDK名称：</b> TiSDK<br>
<b>合作方主体：</b> 南京拓幻智能科技有限公司<br>
<b>信息及权限名称：</b>根据上述权限要求填写具体的信息类型<br>
<b>使用目的：</b> 提供视频、图片人像美化、AR特效技术<br>
<b>隐私政策链接：</b> <a href="https://tillusory.com/privacy">https://tillusory.com/privacy</a>
        </p>

        <li><b>6、最终用户同意方式的示例</b></li>
        <p>
          <b>要求内容：</b>《SDK合规使用说明》应详细说明App获取最终用户授权同意的建议方式，其中需要取得最终用户单独同意的，应显著提示并给出示例。<br>
          <b>接入说明：</b> App首次运行时应当有隐私弹窗，隐私弹窗中应公示简版隐私政策内容并附完整版隐私政策链接，并明确提示最终用户阅读并选择是否同意隐私政策；隐私弹窗应提供同意按钮和拒绝同意的按钮，并由最终用户主动选择。<br>
<b>披露示例：</b><br>
        </p>
        <img src="@/assets/developer/example.png" alt="用户同意方式" />


        <li><b>7、最终用户行使权利的配置说明</b></li>
        <p>
          <b>要求内容：</b> 最终用户对其个人信息的处理享有知情、决定、查阅、复制、补充、更正、撤回授权同意、删除、注销账号等权利。以嵌入接口形式向最终用户提供行使权利的，应提供接口调用方式、示例。<br>
<b>接入说明：</b> 开发者在其App中集成TiSDK后，TiSDK的正常运行会收集必要的最终用户上述设备对应权限进行服务。开发者应根据相关法律法规为最终用户提供行使个人信息主体权利的路径或功能，需要TiSDK配合的，请与TiSDK及时进行联系，我们将与开发者协同妥善解决最终用户的诉求。联系电话：025-66048356，客服电子邮箱地址：sales@tillusory.cn
        </p>

      </ul>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss" scoped>
h2 {
  font-size: 20px;
  font-weight: 500;
  color: #3c4858;
}
h3 {
  font-size: 18px;
  font-weight: 400;
  color: #3c4858;
  margin: 20px 0;
}
p {
  width: 763px;
  margin: 20px 0;
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
li {
  font-size: 16px;
  line-height: 32px;
  font-weight: 400;
  color: #3c4858;
}
span {
  color: #e91e63;
}
img {
  width: 431px;
  margin: 20px 60px;
}
.ul_style {
  padding-left: 10px;
  li {
    line-height: 32px;
    list-style: disc inside none;
  }
  a {
    color: #4c8cf5;
    text-decoration: underline;
  }
}
</style>